<template>
  <v-container fluid>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="confirm" />
    <base-snackbar-notification ref="mainSnackbar" />
    <v-dialog v-model="showCheckInModal" width="500">
      <v-card>
        <v-card-title class="text-h3">
          Log work
        </v-card-title>
        <v-card-subtitle class="mt-0">
          {{ checkinModalDateFormatted }}
        </v-card-subtitle>
        <v-card-text>
          By clicking <span class="font-weight-bold primary--text">LOG WORK</span> you agree that you were at work on the day and were able to do the task's instructed to you by your employer.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="warning" class="my-2 mx-4" @click="closeCheckInModal()">Cancel</v-btn>
          <v-form ref="userFormCheckin" @submit.prevent="checkinUser(checkinModalDate)">
            <v-btn elevation="0" color="primary" class="my-2" @click="checkinUser(checkinModalDate)">Log Work</v-btn>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteCheckInModal" width="500">
      <v-card>
        <v-card-title class="text-h3">
          Delete Logged Work
        </v-card-title>
        <v-card-subtitle class="mt-0">
          {{ checkinModalDateFormatted }}
        </v-card-subtitle>
        <v-card-text>
          Are you sure you want to delete this work?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="warning" class="my-2 mx-4" @click="closeDeleteCheckInModal()">Cancel</v-btn>
          <v-form ref="userFormDeleteCheckin" @submit.prevent="deleteCheckin(checkinModalDate)">
            <v-btn color="primary" class="my-2" @click="deleteCheckin(checkinModalDate)" elevation="0">Yes</v-btn>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showWorkEventDetailModal" width="500">
      <v-card>
        <v-card-title class="text-h3">
          Logged Work
        </v-card-title>
        <v-card-subtitle class="mt-0">
          {{ checkinModalDateFormatted }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="py-1"><span class="font-weight-medium">Payment Status: </span><span :class="processedWorkEvent.colour">{{ processedWorkEvent.paymentStatus }}</span></v-col>
          </v-row>
          <v-row>
            <v-col class="py-1"><span class="font-weight-medium">Status: </span><span :class="processedWorkEvent.colour">{{ processedWorkEvent.status }}</span></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-form ref="userFormWorkEventDetail" @submit.prevent="closeWorkEventDetailModal()">
            <v-btn color="primary" class="my-2" @click="closeWorkEventDetailModal()" elevation="0">OK</v-btn>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row  class="d-flex align-start" height="100%">
      <v-col cols="12" md="12">
        <template v-if="calendarRefreshed">
          <div>
            <v-sheet tile height="48" class="d-flex">
              <v-btn icon color="primary" @click="prev()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title class="ma-2" v-if="calendarTitle">
                {{ calendarTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon color="primary" @click="next()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="480px">
              <v-calendar
                ref="calendar"
                v-model="value"
                color="primary"
                :weekdays="weekday"
                :type="type"
                @change="calendarRefresh"
                @click:date="askCheckin"
                @click:day="askCheckin"
             >
                <template v-slot:day="{ date }">
                  <v-row class="justify-center">
                    <template v-if="workedDates[date]">
                      <v-sheet
                          :key="workedDates[date].id"
                          title="Worked"
                          :color="workedDates[date].colour"
                          class="rounded-circle mt-3"
                      >
                        <v-icon color="white" class="px-1 py-1" dense>{{ workedDates[date].icon }}</v-icon>
                      </v-sheet>
                    </template>
                  </v-row>
                </template>
              </v-calendar>
            </v-sheet>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import UserCreate from "../../components/Base/UserCreate.vue";
import defaultProfile from '@/assets/avatar.png'
export default {
  components: { UserCreate },
  name: "Calendar",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
    this.contractModel = this.$route.params.contractModel
  },
  beforeDestroy() {
    clearInterval(this.calendarRefreshTimer);
  },
  data() {
    return {
      showCalendarView: true,
      showCalendarDetailView: false,
      type: "month",
      mode: "stack",
      weekday: [0, 1, 2, 3, 4, 5, 6],
      value: "",
      workedDates: {},
      workedDatesDetailDate: "",
      workedDatesDetailShortDate: "",
      workEventsObservable: {},
      workEventsObservableSubscription: {},
      notCheckedInUsers: [],
      showCheckInModal: false,
      showDeleteCheckInModal: false,
      showWorkEventDetailModal: false,
      checkinModalDate: "",
      checkinModalDateFormatted: "",
      processedWorkEvent: {},
      calendarTitle: "",
      calendarRefreshed: false,
      calendarRefreshTimer: null,
      contractModel: {},
      show: false,
      panel: 0,
      tabs: ["Daily", "Weekly", "Monthly"],
      dailyRate: "R250",
      weeklyRate: "R1000",
      monthlyRate: "R4000",
      selectedItem: 1,
      tab: "tab-1",
      rates: [
        { title: "Per Day", content: "R1 500.00" },
        { title: "Weekly", content: "R7 500.00" },
        { title: "Monthly", content: "R30 000.00" },
      ],
      dates: ["2018-09-15", "2018-09-20"],
      menu: false,
      items: [
        { text: "User 1", icon: "mdi-account", value: "11" },
        { text: "User 2", icon: "mdi-account", value: "22" },
        { text: "account 3", icon: "mdi-account", value: "33" },
      ],
      // tab: 0,
      newItems: [],
      isLoading: false,
      id: "",
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
      fetchPolicy: "no-cache",
      async result() {
        this.isLoading = true
        const startDate = new Date()
        this.id = this.me.id;
        await this.getEvents(this.id, startDate)
        this.isLoading = false
      },
    },
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              role
              employers
              profilePicture
            }
          }
        }
      `,
      variables() {
        return {
          limit: 999999999,
          query: { employers: { $in: [this.id] }, role: "employee" }, //{},
        };
      },
      watchLoading(isLoading, countModifier) {
        // this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.newItems = data.users.records;
        if (this.newItems.length > 0) {
          this.selectedItem = this.newItems[0];
        }
      },
    },
  },
  methods: {
    defaultProfileImage() {
      return defaultProfile;
    },
    askCheckin ({date}) {
      this.checkinModalDate = date
      this.checkinModalDateFormatted = this.$moment(date).format('dddd, D MMMM')
      const workedDay = new Date(date).toISOString().split('T')[0]
      if(this.workedDates[workedDay]) {
        console.log('workedDay', this.workedDates[workedDay])
        if (this.workedDates[workedDay].processed) {
          this.processedWorkEvent = this.formatProcessedWorkEvent(this.workedDates[workedDay])
          this.showWorkEventDetailModal = true
        } else {
          this.showDeleteCheckInModal = true
        }
      } else {
        if(this.contractModel.wageTracking !== 'automatic' && this.contractModel.wageTracking !== 'employer_must_track') {
          this.showCheckInModal = true
        }
      }
    },
    formatProcessedWorkEvent (workEvent) {
      const workEventStatus = {
        icon: 'mdi-check',
        colour: 'green--text',
        status: 'Approved',
        paymentStatus: 'Paid'
      }
      if (workEvent.requiresApproval) {
        if(workEvent.approved) {
          workEventStatus.status = 'Approved'
        } else if (workEvent.rejected) {
          workEventStatus.status = 'Not Approved'
          workEventStatus.paymentStatus = 'Not Paid'
          workEventStatus.icon = 'mdi-close'
          workEventStatus.colour = 'orange--text'
        } else {
          workEventStatus.status = 'Pending Approval'
          workEventStatus.paymentStatus = 'Not Paid'
          workEventStatus.icon = 'mdi-progress-check'
          workEventStatus.colour = 'orange--text'
        }
      }
      return workEventStatus
    },
    closeCheckInModal() {
      // set the flag to hide the modal
      this.showCheckInModal = false;
    },
    closeDeleteCheckInModal() {
      // set the flag to hide the modal
      this.showDeleteCheckInModal = false;
    },
    closeWorkEventDetailModal() {
      // set the flag to hide the modal
      this.showWorkEventDetailModal = false;
    },
    async calendarRefresh ({start, end}) {
      const calendarDate = new Date(start.date)
      this.isLoading = true
      this.calendarTitle = calendarDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      if(this.id) {
        await this.$apollo.queries.me.refetch()
      }
      await this.getEvents(this.id, start.date)
      try {
        clearInterval(this.calendarRefreshTimer)
      } catch (error) {
        console.log('No refresh timer to clear')
      }
      this.calendarRefreshTimer = setInterval(() => {
        this.watchEvents(this.id, start.date)
      }, 10000)

      this.isLoading = false
    },
    async next() {
      this.$refs.calendar.next()
    },
    async prev() {
      this.$refs.calendar.prev()
    },
    async getEvents(userId, date) {
      if(!userId) return
      if(!date) return
      const month = new Date(date).getMonth() + 1
      const year = new Date(date).getFullYear()
      await this.$apollo.query({
        query: gql`
            query GetWorkEvents($id: ID!, $month: Int!, $year: Int) {
              workEvents(id: $id, month: $month, year: $year) {
                records {
                  id
                  eventDate
                  requiresApproval
                  approved
                  approvedBy
                  approvedAt
                  rejected
                  rejectedBy
                  rejectedAt
                  processed
                  processedAt
                }
              }
            }
          `,
        fetchPolicy: 'no-cache',
        variables: {
          id: userId,
          month: month,
          year: year
        },

      }).then((data) => {
        const workEvents = _.get(data, 'data.workEvents.records', [])
        if (_.size(workEvents) > 0) {
          _.forEach(workEvents, (record) => {
            const workedDayObjDate = new Date(record.eventDate).toISOString().split('T')[0]
            const approved = this.$_.get(record, 'approved', false)
            const rejected = this.$_.get(record, 'rejected', false)
            const processed = this.$_.get(record, 'processed', false)
            const workedDayObj = {
              id: record.id,
              datetime: record.eventDate,
              requiresApproval: record.requiresApproval,
              approved: approved,
              rejected: rejected,
              processed: processed,
              colour: this.returnWorkEventColour(record.requiresApproval, record.approved, record.processed, rejected),
              icon: this.returnWorkEventIcon(record.requiresApproval, record.approved, record.processed, rejected)
            }
            this.$set(this.workedDates, workedDayObjDate, workedDayObj)
          })

        }
      }).catch((err) => {
        console.log('Error:', err)
        this.$refs.confirm.open("Error", "There was a problem while loading your Worked Days. Please try again or contact support.", "error")
      })
      this.calendarRefreshed = true
    },
    async watchEvents(userId, date) {
      if(!userId) return
      if(!date) return
      const month = new Date(date).getMonth() + 1
      const year = new Date(date).getFullYear()
      await this.$apollo.query({
        query: gql`
            query GetWorkEvents($id: ID!, $month: Int!, $year: Int) {
              workEvents(id: $id, month: $month, year: $year) {
                records {
                  id
                  eventDate
                  requiresApproval
                  approved
                  approvedBy
                  approvedAt
                  rejected
                  rejectedBy
                  rejectedAt
                  processed
                  processedAt
                }
              }
            }
          `,
        fetchPolicy: 'no-cache',
        variables: {
          id: userId,
          month: month,
          year: year
        },

      }).then((data) => {
        const workEvents = _.get(data, 'data.workEvents.records', [])
        if (_.size(workEvents) > 0) {
          _.forEach(workEvents, (record) => {
            const workedDayObjDate = new Date(record.eventDate).toISOString().split('T')[0]
            const approved = this.$_.get(record, 'approved', false)
            const rejected = this.$_.get(record, 'rejected', false)
            const processed = this.$_.get(record, 'processed', false)
            const workedDayObj = {
              id: record.id,
              datetime: record.eventDate,
              requiresApproval: record.requiresApproval,
              approved: approved,
              rejected: rejected,
              processed: processed,
              colour: this.returnWorkEventColour(record.requiresApproval, approved, processed, rejected),
              icon: this.returnWorkEventIcon(record.requiresApproval, approved, processed, rejected)
            }
            this.$set(this.workedDates, workedDayObjDate, workedDayObj)
          })

        }
      }).catch((err) => {
        console.log('Error:', err)
        this.$refs.confirm.open("Error", "There was a problem while loading your Worked Days. Please try again or contact support.", "error")
      })
    },
    returnWorkEventColour(requiresApproval, approved, processed, rejected) {
      if (processed) {
        if(requiresApproval) {
          if (approved) {
            return 'green lighten-2'
          } else {
            return 'orange lighten-3'
          }
        } else {
          return 'green lighten-2'
        }
      }
      if( requiresApproval) {
        if (approved){
          return 'secondary'
        } else if(rejected){
          return 'red darken-4'
        } else {
          return 'amber lighten-3'
        }
      }
      return 'secondary'
    },
    returnWorkEventIcon(requiresApproval, approved, processed, rejected) {
      if (processed) {
        if(requiresApproval) {
          if (approved) {
            return 'mdi-currency-usd'
          } else {
            return 'mdi-currency-usd-off'
          }
        } else {
          return 'mdi-currency-usd'
        }
      }
      if( requiresApproval) {
        if (approved){
          return 'mdi-check'
        } else if (rejected) {
          return 'mdi-close'
        } else {
          return 'mdi-progress-check'
        }
      }
      return 'mdi-check'
    },
    async checkinUser(date) {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: gql`
            mutation createWorkEvent($workEvent: WorkEventInput!) {
              createWorkEvent(workEvent: $workEvent) {
                id
                eventDate
                requiresApproval
                approved
                approvedBy
                approvedAt
                processed
                processedAt
              }
            }
          `,
        variables: {
          workEvent: {
            assignedTo: this.me.id,
            eventType: 'WORK',
            eventUnit: this.contractModel.wagePeriod,
            createdBy: this.me.id,
            createdAt: new Date().getTime(),
            eventDate: new Date(date).getTime(),
          },
        },
      }).then((result) => {
        const workedDayObjDate = new Date(date).toISOString().split('T')[0]
        const workedDayObj = {
          id: result.data.createWorkEvent.id,
          datetime: result.data.createWorkEvent.eventDate,
          requiresApproval: result.data.createWorkEvent.requiresApproval,
          approved: result.data.createWorkEvent.approved,
          processed: result.data.createWorkEvent.processed,
          colour: this.returnWorkEventColour(result.data.createWorkEvent.requiresApproval, result.data.createWorkEvent.approved, result.data.createWorkEvent.processed),
          icon: this.returnWorkEventIcon(result.data.createWorkEvent.requiresApproval, result.data.createWorkEvent.approved, result.data.createWorkEvent.processed)
        }
        this.$set(this.workedDates, workedDayObjDate, workedDayObj)
        this.isLoading = false;
        this.showCheckInModal = false;
        this.$refs.mainSnackbar.open("You have successfully Checked In.", "success")
      })
      .catch((e) => {
        this.isLoading = false;
        this.showCheckInModal = false;
        this.$refs.confirm.open("Error", "Your Check In failed. Please try again or contact support.", "error")
      })
    },
    async deleteCheckin(date) {
      const workedDay = new Date(date).toISOString().split('T')[0]
      if(!this.workedDates[workedDay]) {
        this.$refs.confirm.open("Error", "There was a problem while deleting the check-in. Please try again or contact support.", "error")
      }
      this.isLoading = true
      await this.$apollo.mutate({
        mutation: gql`
            mutation ($id: ID!) {
              deleteWorkEvent(id: $id) {
                id
                deleted
              }
            }
          `,
        variables: {
          id: this.workedDates[workedDay].id
        }
      }).then((data) => {
        this.$delete(this.workedDates, workedDay)
        this.isLoading = false
        this.showDeleteCheckInModal = false
      }).catch((error) => {
        console.log('Error: ', error)
        this.isLoading = false
        this.showDeleteCheckInModal = false
        this.$refs.confirm.open("Error", "There was a problem while deleting the check-in. Please try again or contact support.", "error")
      })
    },
  },
  computed: {

  },
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.v-sheet.v-card {
  cbackground: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}

.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  /* overflow: hidden; */
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#A5A6F6;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #5d5fef;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}
</style>
